import {Text, Html, PresentationControls, Float, Environment, useGLTF, ContactShadows} from '@react-three/drei'
import React, { useRef } from 'react';


export default function Experience()
{
    const textRef = useRef();



    const computer = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf')
    return (
      <>
        <Environment preset="city" />

        <color args={["#cec4c2"]} attach="background" />

        <PresentationControls
          global
          rotation={[0, 0.1, 0]}
          polar={[-0.01, 0.02]}
          azimuth={[-0.03, 0.03]}
          config={{ mass: 1, tension: 800 }}
          snap={{ mass: 2, tension: 800 }}
        >
          <Float rotationIntensity={0.4}>
            <rectAreaLight
              width={2.5}
              height={1.65}
              intensity={65}
              color={"#239ff9"}
              rotation={[0.1, Math.PI, 0]}
              position={[0, 0.55, -1.15]}
            />
            <primitive object={computer.scene} position-y={-0.8}>
              <Html
                transform
                wrapperClass={"htmlScreen"}
                distanceFactor={1.25}
                position={[-1.08, 2.48, -2]}
                rotation-x={-0.256}
              >
                <iframe src="https://davidclabaugh.framer.website/" />
                This is going to be an iFrame
              </Html>
            </primitive>

            <Text
              font={"./lato-v24-latin-regular.woff2"}
              fontSize={0.72}
              color={"#3c3c3c"}
              position={[2, 0.5, 0.5]}
              rotation-y={[-1.25]}
              maxWidth={2}
              textAlign="left"
              letterSpacing={0.05}
            >
              David Clabaugh
            </Text>
            <Text
              ref={textRef}
              color={"#e75b00"}
              font={"./lato-v24-latin-regular.woff2"}
              fontSize={0.24}
              position={[0.9, 1.6, 1]}
              rotation-y={[-1.25]}
              maxWidth={2}
              textAlign="left"
              letterSpacing={0.05}
              onPointerEnter={() => {
                document.body.style.cursor = "default";
                textRef.current.color = "white";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "pointer";
                textRef.current.color = "#e75b00";
              }}
              onClick={() => {
                window.open(
                  "https://thefantasticplanet.artstation.com/",
                  "_blank"
                );
              }}
            >
              Portfolio
            </Text>
          </Float>
        </PresentationControls>

        <ContactShadows position-y={-1.2} opacity={0.4} scale={5} blur={2.4} />
      </>
    );
}